/*!
 * admin
@import url(//fonts.googleapis.com/css?family=Overpass);
@import url("https://overpass-30e2.kxcdn.com/overpass.css");
 */

body {
    font-family: Overpass, open_sansregular, HelveticaNeue, "Helvetica Neue",
        Helvetica, Arial, sans-serif;
    background-color: #fff;
    background-image: url(/static/images/green.png);
    background-repeat: no-repeat;
    background-size: 230px 100vh;
}

.navbar-dark {
    background-color: #39435f;
}

.navbar-dark::before {
    content: "PROFESSIONNEL";
    position: absolute;
    transform: translate(250px, 5px);
    color: white;
    letter-spacing: 10px;
}

.btn-user li a {
    padding: 0px 5px;
}

#body_out {
    padding-top: 0;
}
#body_in {
    padding-top: 0;
}

.logo {
    margin: 15px !important;
    height: 61px !important;
}

.user-icon {
    border-top: solid 10px transparent;
    border-bottom: solid 9px transparent;
    border-right: solid 10px transparent;
    border-left: solid 9px transparent;
}

.navbar-header .navbar-brand {
    color: #1a92af;
}
.navbar-toggle.navbar-toggle-left {
    float: left;
    margin-left: 15px;
}

#content {
    padding: 30px;
    margin: 0;
}

#header_title {
    font-size: 30px;
    text-align: left;
    color: #000;
    text-transform: none;
    height: 70px;
    padding: 30px 30px 0 30px;
    color: #17a2b8;
    font-style: italic;
}

#header_in .dropdown .dropdown-item:hover {
    color: #fff;
    background-color: #1a92af;
    border-color: #fff;
}

.dropdown-item a {
    text-decoration: none;
}

.dropdown-item {
    padding: 5px 10px;
}

.dropdown-menu {
    min-width: unset;
}

.page-header {
    margin-top: 0;
}
.page-header h3,
.page-header h4 {
    color: #1a92af;
}

.panel-default > .panel-heading {
    background-color: #fff;
}

/* overflow + margin-right -> keep scroll sidebar is higher than vindow but hide scrollbar */
#sidebar {
    padding: 0;
}
#xsidebar .wrap {
    margin-right: 20px;
    background-color: #008daa;
    height: 100%;
}
#sidebar .nav-stacked > li + li {
    margin-top: 0;
}
#sidebar ul.nav,
.sidebar-offcanvas {
    background-color: #008daa;
}
#sidebar ul.nav > li > a {
    display: block;
    color: #fff;
    font-size: 17px;
    text-align: center;
    padding: 14px 0 12px 0;
    text-decoration: none;
}
#sidebar ul.nav > li.active > a {
    background-color: #39435f;
}

#sidebar ul.nav > li > ul.nav {
    background-color: #007f99;
}
#sidebar ul.nav > li > ul.nav li.active > a::before,
#dashboard.active a::before {
    content: "";
    position: absolute;
    left: 230px;
    display: block;
    border-top: 0px solid transparent;
    border-right: 0px solid transparent;
    border-left: 10px solid white;
    border-bottom: 10px solid white;
    width: 10px;
    height: 10px;
    transform: translate(-5px, 6px) rotate(45deg);
}

#sidebar .nav .fleche {
    float: right;
    padding-right: 10px;
}
#sidebar ul.nav > li.mm-active a .fleche::before {
    content: "\f078";
    position: absolute;
    transform: translate(-15px, 0px);
}
#sidebar ul.nav > li a .fleche::before {
    content: "\f053";
    position: absolute;
    transform: translate(-10px, 0px);
}

@media screen and (min-width: 575px) {
    .sidebar-offcanvas {
        min-width: 230px !important;
    }
    .toggle-wrap {
        flex-wrap: nowrap !important;
    }
}
/* see bootstrap3-offcanvas.css for max-width: 767px */
@media screen and (max-width: 575px) {
    .sidebar-offcanvas {
        width: 100% !important;
    }
    .toggle-wrap {
        flex-wrap: wrap !important;
    }
    #sidebar ul.nav > li > ul.nav li.active > a::before,
    #dashboard.active a::before,
    #sidebar ul.nav > li a .fleche {
        display: none;
    }
    body {
        background-image: none;
    }
    .navbar-dark::before {
        content: "PRO";
        top: 40px;
    }
    .cell-btn-nolabel {
        white-space: unset !important;
    }
    .btn-nolabel {
        margin-bottom: 3px;
    }

    .top-banner {
        border-bottom: solid 1px white !important;
    }

    .top-banner ul.dropdown-menu {
        margin-left: 20px !important;
    }

    .sm-no-margins {
        margin: 0 !important;
    }
}

#partage_heure_debut_hour,
#partage_heure_debut_minute,
#partage_heure_fin_hour,
#partage_heure_fin_minute {
    float: left;
    width: 40%;
}
.time_widget_sep_1,
.time_widget_sep_2 {
    float: left;
    margin: 0 10px;
}

/*--- fix bootstrap*/
.container-fluid {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}
/*---*/

th {
    color: white;
}
.btn-secondary {
    background-color: #999;
    border: none;
}
.rounded-pill {
    border-radius: 20px !important;
}

#sidebar #dashboard a {
    background-image: url(/static/images/home.png);
    background-repeat: no-repeat;
}
#sidebar #configurations > a {
    background-image: url(/static/images/config.png);
    background-repeat: no-repeat;
}
#sidebar ul#metismenu > li#rentals > a {
    background-image: url(/static/images/key.png);
    background-repeat: no-repeat;
}

.plugin-status {
    padding-left: 35px;
    margin-left: 0px;
    margin-right: 20px;
    margin-top: 30px;
}
.plugin-status::before {
    position: absolute;
    content: "";
    border: solid 1px rgb(202, 202, 202);
    width: 24px;
    height: 24px;
    transform: translate(-30px, -2px);
}
.plugin-enabled::before {
    background-color: #17a2b8;
}
.plugin-disabled::before {
    background-color: white;
}

.custom-control-label {
    padding: 7px 8px 5px 8px;
    color: #444;
}
.custom-control-label:before {
    background-color: #ddd;
    width: 24px;
    height: 24px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #008daa;
    width: 24px;
    height: 24px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    width: 24px;
    height: 24px;
}

label,
.col-form-label {
    color: rgb(122, 122, 122);
    margin: 20px 0px 2px 0px;
}

input::placeholder {
    color: #008eaa9a !important;
    font-style: italic;
}

#appbundle_account_checkinTime select,
#appbundle_account_checkoutTime select {
    width: unset;
}

.top-label {
    width: 100%;
    border-bottom: solid 1px rgb(197, 197, 197);
    color: rgb(122, 122, 122);
    margin: 20px 0px 2px 0px;
    padding: 5px 0px;
}

.top-section {
    width: 100%;
    border-bottom: solid 1px #008eaa9a;
    color: #008eaa;
    margin: 20px 0px 15px 0px;
    padding: 5px 0px;
    font-size: 1.2rem;
    font-style: italic;
}

form .top-label {
    margin: 0px 0px 2px 0px;
}

.left-label {
    margin-right: 10px;
    color: rgb(122, 122, 122);
}
.empty-field {
    color: #aaa !important;
    font-style: italic;
}

.display-field {
    padding: 5px 10px;
    width: 100%;
    color: #222;
}
.tooltip.show {
    opacity: 1;
}

#appbundle_user .invalid-feedback {
    color: #aa0000;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 1px 5px;
}
#appbundle_user label {
    margin: 5px 0px 2px 0px;
}

#top-status span {
    padding: 2px 10px 2px 10px;
}

.filter-form label {
    margin: 0px 0px 2px 0px;
}

.filter-form .form-group {
    margin: 0px 10px 8px 0px !important;
}

.table-index th {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 0 !important;
}
.table-index td.no-border {
    border-bottom: 0 !important;
}

table.lockers th,
.lockers-view th,
.lockers-view td {
    padding: 5px 10px;
}

table.lockers tbody td .dummy {
    padding: 5px !important;
}

table.lockers td,
table.lockers th {
    border: solid 1px white;
}

ul.dropdown-menu a {
    white-space: nowrap;
}

th:nth-child(odd) {
    background-color: #aaa;
}

th:nth-child(even) {
    background-color: #bbb;
}

.table th,
.table td {
    border: 1px solid #ffffff;
    border-bottom: 1px solid #ddd;
}

.fa-question-circle {
    margin-left: 10px;
    margin-right: 5px;
    color: #6b7ca7;
}
.label-help {
    white-space: nowrap;
}
.label-help::after {
    font-family: "Font Awesome 5 Free";
    color: #65718f !important;
    margin-left: 10px;
    margin-right: 5px;
    content: "\f059";
    font-weight: 900;
}

.checkbox-help {
    padding-top: 30px;
    padding-right: 5px;
}

label.required.label-help::after {
    content: "* \f059" !important;
}

.tooltip > .tooltip-inner {
    background-color: #39435f;
    color: white;
    border: solid 1px #39435f;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #39435f;
    color: #39435f;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #39435f;
    color: #39435f;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #39435f;
    color: #39435f;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #39435f;
    color: #39435f;
}

legend {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
fieldset {
    margin-top: 5px;
}
.top-banner {
    z-index: 10000 !important;
}

.top-banner ul.dropdown-menu {
    margin-left: 30px !important;
}

td.cell-btn-nolabel {
    padding: 5px !important;
}

div.vertical-space {
    height: 40px;
}

.sms-template {
    min-height: 250px;
}
/* Start Overpass fix */
.btn {
    padding-top: 10px;
}

.alert {
    padding-top: 16px;
}

.navigation li span,
.navigation li a {
    padding-top: 10px;
}

.table td {
    padding-top: 14px;
}

.table th {
    padding-top: 14px;
}

.table-index th {
    padding-top: 8px;
}

input,
select {
    padding-top: 8px !important;
}

.invoice-list span {
    padding-top: 10px !important;
}
/* End Overpass fix */

/* pdf */

body.pdf {
    background: none;
}
/* end pdf */
