/*!
 * app
 */

@font-face {
  font-family: "open_sansregular";
  src: url("../fonts/OpenSans-Regular-webfont.eot");
  src: url("../fonts/OpenSans-Regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/OpenSans-Regular-webfont.woff") format("woff"),
    url("../fonts/OpenSans-Regular-webfont.ttf") format("truetype"),
    url("../fonts/OpenSans-Regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sanssemibold";
  src: url("../fonts/OpenSans-Semibold-webfont.eot");
  src: url("../fonts/OpenSans-Semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/OpenSans-Semibold-webfont.woff") format("woff"),
    url("../fonts/OpenSans-Semibold-webfont.ttf") format("truetype"),
    url("../fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: open_sansregular, HelveticaNeue, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #fff;
}

#header_out {
  height: 100px;
  background-color: #39435f;
}
#header_out_in {
  height: 100px;
}
#header_in img.logo {
  margin-top: 0px;
  height: 100px;
}
#header_title {
  font-size: 25px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
  height: 50px;
  padding-top: 7px;
}

#body_out {
  background-color: #fff;
}
#content {
  margin: 50px 0 100px 0;
}
#footer_in {
  margin-top: 10px;
}

.titlebar {
  background-color: #f4f4f4;
  border: 1px solid #eee;
  padding: 12px 12px 8px 18px;
  margin-bottom: 20px;
}
.titlebar .title {
  font-size: 21px;
  color: #777;
}

img {
  max-width: 100%;
  height: auto;
}

.well {
  background-color: #f4f4f4;
  border: 1px solid #eee;
  box-shadow: none;
}

label {
  font-weight: normal;
  margin: 6px 0 2px 0;
  color: #999;
}

form .help {
  margin-bottom: 15px;
  margin-top: -10px;
  color: #999;
  font-size: 14px;
}

.alert li {
  list-style: none;
}

.form-group .alert {
  margin-bottom: -4px;
}

.btns_list_vert .btn {
  margin-bottom: 1px;
  display: block;
}
.btns_list_horiz .btn {
  margin: 1px;
}
.btns_list_horiz form {
  display: none;
}

.panel > .panel-with-table.panel-collapse.collapse.in {
  border-bottom: 1px solid #ddd;
}

table th {
  font-weight: normal;
}

.form-control:focus {
  box-shadow: none;
}

form label.required:after {
  content: "*";
}

a.asc::before {
  content: "▲";
}
a.desc::before {
  content: "▼";
}

a:focus {
  outline: none;
}

.form-group.wbx_search_many_filter .list-group {
  margin-bottom: -1px;
}

textarea#product_description {
  height: 200px;
}

.thumbnail {
  overflow: hidden;
}

.wbxfile_btn_delete {
  position: absolute;
  top: 0;
  right: 15px;
}

#page_login .panel-body .picto,
#page_signup .panel-body .picto,
#page_password_forget .panel-body .picto,
#page_user_not_active .panel-body .picto {
  text-align: center;
  color: #dddddd;
  font-size: 60px;
  margin-bottom: 20px;
}
#page_login .panel-footer .btn-xs {
  margin-top: 8px;
}

/*fix bootstrap*/
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.listbtnhead {
  margin: 6px 0;
}
.listbtnbody {
  float: left;
  margin: 0px 10px 10px 10px;
}

.row.misd_phone_number {
  padding: 0 15px;
}
.row.misd_phone_number2 .col-xs-4,
.row.misd_phone_number2 .col-xs-8 {
  padding-left: 0px;
  padding-right: 0px;
}

#page_signup_validate img.app-store {
  width: 200px;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.dropdown-menu li a {
  color: #000;
  padding: 3px 20px;
}

.btn a {
  color: #333;
}

.btn-default {
  background-color: #fff;
  border-color: #cccccc;
}

.btn-user {
  background-color: #39435f;
  border-color: #39435f;
  color: #fff;
}

.btn-user li a {
  color: #fff;
  padding: 3px 20px;
}

th:nth-child(odd) {
  background-color: #9b9b9b;
}

th:nth-child(even) {
  background-color: #a9a9a9;
}

.table th,
.table td {
  border: 1px solid #ffffff;
  border-bottom: 1px solid #ddd;
}

.sidebar li {
}

.login-body {
  background: linear-gradient(
    to right,
    white 0%,
    white 50%,
    #000000 50%,
    #39435f 50%,
    #39435f 100%
  );
}

.login_panel {
  background-color: #008daa;
  border-radius: 5px;
  width: 450px;
}

.login_panel label {
  color: #fff;
}

.login_panel .btn {
  background-color: #39435f;
  color: #fff;
  border-radius: 20px;
}

#header_out_login {
  height: 300px;
}
